<template>
  <header>

    <div class="nav" style="">
      <a href="/" style="display:flex;">
        <img style="height:48px;width:48px;" src="https://cdn.side.nz/v/2f9a8bcc-b51f-4a18-be2f-3626fee11460.svg">
      </a>

      <nav class="menu-col" style="height:100%;margin-left:40px;">
        <a href="/testimonials" class="nav-item">
          <div>Testimonials</div>
          <!-- <svg style="margin-left:7px;" xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="0 0 451.847 451.847">
          	<path fill="#13b5ea" d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
          		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
          		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
          </svg> -->
        </a>

        <a href="/partners" class="nav-item">
          <div>Partners</div>
          <!-- <svg style="margin-left:7px;" xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="0 0 451.847 451.847">
          	<path fill="#13b5ea" d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
          		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
          		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
          </svg> -->
        </a>


          <a href="/pricing" class="nav-item">
            <div>Pricing</div>
            <!-- <svg style="margin-left:7px;" xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="0 0 451.847 451.847">
            	<path fill="#13b5ea" d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
            		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
            		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
            </svg> -->
          </a>

          <a href="/blog" class="nav-item">
            <div>Blog</div>
            <!-- <svg style="margin-left:7px;" xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="0 0 451.847 451.847">
            	<path fill="#13b5ea" d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
            		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
            		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
            </svg> -->
          </a>


        <a href="/contact" class="nav-item">
          <div>Contact</div>
          <!-- <svg style="margin-left:7px;" xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="0 0 451.847 451.847">
          	<path fill="#13b5ea" d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
          		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
          		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
          </svg> -->

        </a>

      </nav>

      <div @click.prevent="toggleMenu" class="mobile-menu" style="cursor:pointer;margin-right:10px;">

        <svg height="20px" viewBox="0 -21 384 384" width="20px" xmlns="http://www.w3.org/2000/svg">
          <path fill="#213b55" d="m362.667969 0h-341.335938c-11.753906 0-21.332031 9.578125-21.332031 21.332031v42.667969c0 11.753906 9.578125 21.332031 21.332031 21.332031h341.335938c11.753906 0 21.332031-9.578125 21.332031-21.332031v-42.667969c0-11.753906-9.578125-21.332031-21.332031-21.332031zm0 0"/>
          <path fill="#213b55" d="m362.667969 128h-341.335938c-11.753906 0-21.332031 9.578125-21.332031 21.332031v42.667969c0 11.753906 9.578125 21.332031 21.332031 21.332031h341.335938c11.753906 0 21.332031-9.578125 21.332031-21.332031v-42.667969c0-11.753906-9.578125-21.332031-21.332031-21.332031zm0 0"/>
          <path fill="#213b55" d="m362.667969 256h-341.335938c-11.753906 0-21.332031 9.578125-21.332031 21.332031v42.667969c0 11.753906 9.578125 21.332031 21.332031 21.332031h341.335938c11.753906 0 21.332031-9.578125 21.332031-21.332031v-42.667969c0-11.753906-9.578125-21.332031-21.332031-21.332031zm0 0"/>
        </svg>

      </div>
    </div>

    <Menu v-if="menuOpen" @toggleMenu="toggleMenu" />

    <div class="menu-col" style="">
      <a href="tel:078389700" style="font-size:15px;margin-right:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
        <div>07 838 9700</div>
      </a>

      <a href="https://login.xero.com/identity/user/login" target="_blank" style="font-size:15px;border: 2px solid rgba(33, 59, 85, 0.3);border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#fff;cursor:pointer;">
        <div>Xero Login</div>
      </a>
    </div>


  </header>
</template>

<script>
import Menu from './Menu.vue'
export default {
  components: {
    Menu
  },
  data(){
    return {
      menuOpen: false
    }
  },
  methods: {
    toggleMenu(){
      this.menuOpen = !this.menuOpen
      document.body.style.overflow = this.menuOpen ? 'hidden' : ''
    }
  }
}
</script>

<style scoped>
.nav-item {
  font-weight:600;
  margin-left: 40px;
  display:flex;align-items:center;
  height:100%;
  cursor:pointer;
  font-size: 20px;
  color:#172c40;

}

.menu-col {
  display:flex;
}

.mobile-menu {
  display:none;
}

.nav {
  display: flex;
  height: 100%;
  align-items: center;
}

header {
  background-color:#fff;height:72px;padding:0 40px;display:flex;justify-content:space-between;align-items:center;
}

@media only screen and (max-width: 768px) {
  header {
    padding: 0 20px;
  }

  .menu-col {
    display:none;
  }

  .mobile-menu {
    display:flex;
  }

  .nav {
    justify-content: space-between;
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  header {
    padding: 0 20px;
  }

  .menu-col {
    display:none;
  }

  .mobile-menu {
    display:flex;
  }

  .nav {
    justify-content: space-between;
    width: 100%;
  }
}
</style>
