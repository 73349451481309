<template>
  <main style="">
    <div class="header-title" style="">Privacy & Terms</div>
      <ol>
      <li><strong>Terms of Engagement and Internet Terms</strong>
      <ol>
      <li>By clicking acceptance on this site when purchasing services, as the person authorised to do so, a customer agrees to the following combined terms of service, internet terms and engagement from Slater Chartered Accountants, 2 Bryce Street Hamilton New Zealand 3204 (referred to here as “Slater.”) We advise that you must click acceptance of these terms in the purchase process attached to each service we sell elsewhere on this site and when you do so, you accept this policy when purchasing. We advise you also print out a copy of these Terms and Conditions and Terms of Engagement and Internet Terms for your personal records</li>
      </ol></li>

      <li><strong>Introduction</strong>
      <ol>
      <li>We regard customer privacy as an important part of our relationship with our customers. The following privacy policy applies to all Slater Chartered Accountants users, and conforms to Internet privacy standards..</li>
      <li>If you have questions or concerns regarding this statement, you should first contact Slater Chartered Accountants at 07 8389700 and speak with Dinu Harry, Senior Partner.</li>
      </ol></li>

      <li><strong>Collection and Use of Information</strong>
      <ol>
      <li>In order to use the Slater Chartered Accountants website, we may require information from you and other sources in order to provide the best service possible.</li>
      <li>All correspondence may also be collected and stored, particularly in regard to sales, support and accounts, including Email, financial information and correspondence.</li>
      <li>Ownership of Work Papers – regarding this, we claim a lien over all books and records in our possession until all work has been performed and all fees rendered have been paid. Work papers that we create will remain our property.</li>
      </ol></li>

      <li><strong>Storage of Collected Information</strong>
      <ol>
      <li>The security of your personal information is important to us. When you enter sensitive information (such as credit card numbers) on our website, we encrypt that information using secure socket layer technology (SSL). When Credit Card details are collected, we simply pass them on in order to be processed as required. We never permanently store complete Credit Card details.</li>
      <li>We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. If you have any questions about security on our Website, you can email us at info@slater.co.nz</li>
      </ol></li>

      <li><strong>Access to Collected Information</strong>
      <ol>
      <li>If your personally identifiable information changes, or if you no longer desire our services, you may correct, update, delete or deactivate it by emailing us at info@slater.co.nz</li>
      </ol></li>

      <li><strong>Communications</strong>
      <ol>
      <li>Slater Chartered Accountants uses personally identifiable information for essential communications, such as emails, accounts information, and critical service details. We may also use this information for other purposes, including some promotional Emails. If at any you do not wish to receive such correspondence, you can request to be removed from any mailing lists by emailing us at info@slater.co.nz or by opting out.</li>
      <li>Our customer service contact details are info@slater.co.nz</li>
      <li>Slater Chartered Accountants is not liable for any security or privacy breach or hacking that occurs as a consequence of your failure to use systems and online procedures that are secure and authenticated when communicating information to Slater. You play an important role in protecting your information by taking basic precautions to protect your computer and data. You should keep your computer secure, only deal online with people and organisations you know and trust, and check that your personal information travels across the internet on secure connections. There are many resources about computer security (such as websites, books and magazines) that can help guide you on what security precautions are appropriate for your situation.</li>
      </ol></li>

      <li><strong>Third Parties</strong>
      <ol>
      <li>Slater Chartered Accountants may at its discretion use other third parties to provide essential services on our site or for our business processes. We may share your details as necessary to third parties to provide that service.</li>
      <li>Slater also need certain information to complete the services agreed to. As already stated, we protect our information when collecting, using, and storing it. There may be occasions when we need additional information from third parties such as solicitors, bankers, stock and station agents, or other organisations, in order to complete the services agreed to. Under the terms of the Privacy Act (1993) you authorise us to seek and to provide information relating to your affairs direct from such third parties as you specifically name, either verbally or in writing.</li>
      <li>These third parties are prohibited from using your personally identifiable information for any other purpose. We take no responsibility for inappropriate use by third parties.</li>
      <li>Slater Chartered Accountants does not share any information with third parties for any unknown or unrelated uses. In addition to this, Slater Chartered Accountants as members of the New Zealand Institute of Chartered Accountants are subject to and bound by the rules and procedures of the Institute, so work and files are subject to practice review rules and requirements. Compliance with professional rules and standards is monitored. These procedures may require us to disclose part or all of a client’s information to the Institute, its reviewers and/or disciplinary bodies. The clients therefore acknowledges and agrees to disclosure of files and information made for these purposes. We assure the client that the same ethical standards regarding confidentiality that we adhere to will apply in this circumstance.</li>
      </ol></li>

      <li><strong>Legal</strong>
      <ol>
      <li>We reserve the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with the work you have contracted us to do for you, a judicial proceeding, court order, or legal process served on our Website.</li>
      </ol></li>

      <li><strong>Links</strong>
      <ol>
      <li>Links on the Slater Chartered Accountants site to external entities are not covered within this policy. The terms and conditions set out in this privacy statement only cover the domain names directly maintained by Slater Chartered Accountants.</li>
      </ol></li>

      <li><strong>Timely Basis of Information</strong>
      <ol>
      <li>The information you need to supply to us to complete the services we have both agreed to, must be provided on a timely basis, so that the engagement can be completed within the limits imposed by the law. Failure of the customer to supply the information on time and within set time frames is the responsibility of the customer.</li>
      </ol></li>

      <li><strong>Retention of Records</strong>
      <ol>
      <li>We will store accounting records that we hold on your behalf for a period of 7 years after the applicable balance date. At the end of that period, unless you write requesting they be forwarded to you, the records will be destroyed using a secure document destruction method.</li>
      </ol></li>

      <li><strong>Changes to Privacy Policy</strong>
      <ol>
      <li>If we decide to change our privacy policy, we will post those changes to our Privacy of Information section, the homepage, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we can disclose it. We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, we will require you to sign an updated terms and conditions.</li>
      </ol></li>

      <li><strong>Slater Chartered Accountants Security Policy</strong>
      <ol>
      <li>Slater Chartered Accountants uses the eWAY and PayPal Payment Gateways for its online credit card transactions.</li>
      <li>eWAY  processes online credit card transactions for thousands of New Zealand  merchants, providing a safe and secure means of collecting payments via the Internet.</li>
      <li>All online credit card transactions performed on this site using the eWAY gateway are secured payments.</li>
      <li>payments are fully automated with an immediate response.</li>
      <li>Your complete credit card number cannot be viewed by Statutory or any outside party.</li>
      <li>All transactions are performed under 256 Bit SSL Certificate.</li>
      <li>All transaction data is encrypted for storage within eWAY’s bank-grade data centre, further protecting your credit card data.</li>
      <li>eWAY is an authorised third party processor for all the major New Zealand banks.</li>
      <li>eWAY at no time touches your funds; all monies are directly transferred from your credit card to the merchant account held by slater.co.nz</li>
      <li>For more information about eWAY and online credit card payments, please visit www.eway.com.au</li>
      </ol></li>
      </ol>

      <h2>Delivery Policy</h2>
      <ol>
      <li><strong>Delivery of Services</strong>
      <ol>
      <li>Delivery of Services After ordering online, you will receive an email confirmation from eWAY containing your order details (if you have provided your email address). We will normally confirm receipt of your order within a few minutes of ordering. We will attempt to send your software/license/access code via email within the next working day. If you wish to query a delivery please contact us at info@slater.co.nz</li>
      </ol></li>

      <li><strong>Transaction Currency</strong>
      <ol>
      <li>All Slater prices are displayed in New Zealand dollars.</li>
      </ol></li>

      <li><strong>Refunds and Cancellation Policy</strong>
      <ol>
      <li>Slater has a no refunds policy. No refunds once Slater commences work. If we have commenced work or if it’s for a period where we would normally commence the work then we require full payment for that assignment.</li>
      </ol></li>

      <li><strong>Terms of Trade</strong>
      <ol>
      <li>All invoices are payable in advance. If your invoice is not paid within 30 days after the due date and the work has been performed by us and is completed for you, our debt recovery agency charge you collection costs of at least 25% of the unpaid portion of the price, but not less than $100.00. Where the total agency, legal and other costs arising from recovery of any amount owing exceeds the debt recovery fee charged, our agent is also entitled to recover such additional costs from you. This clause is intended to be for the benefit of and be enforceable by our debt recovery agency under the Contracts (Privacy) Act 1982.</li>
      <li>You agree to indemnify Slater Chartered Accountants against all costs, whether commission, legal fees (including on a solicitor-client basis) or otherwise, incurred us in recovering any moneys owed for goods and services provided to you that may be outstanding from time to time pursuant to the terms of this agreement. Any costs whatsoever not recoverable from the invoiced entity will be personally recoverable from the instructing person requesting the service from Slater Chartered Accountants.</li>
      <li>In addition to any collection or legal costs Slater Chartered Accountants will be entitled to charge interest at the rate of 24% per annum (2% per month) on all amounts that have not been paid in full from the due date of the invoice should forward calculation of the invoice not be practical.</li>
      </ol></li>

      <li><strong>Disputes</strong>
      <ol>
      <li>Further, in the unfortunate circumstance that a disagreement arises regarding fees, if the matter cannot be resolved amicably through discussion (the preferred option), then Slater will also refer the matter to the Fees Resolution Service of the Institute of Chartered Accountants. By signing this agreement, the client agrees to this procedure when a prior resolution has not been reached. Any costs incurred in the collection of payment will be your responsibility. Terms of Services</li>
      </ol></li>

      <li><strong>The terms of services rendered are as follows:</strong>
      <ol>
      <li>You agree to provide us with accurate and complete information necessary to compile all financial statements, returns and reports so we can do the job for you. The responsibility for the accuracy and completeness of financial statements remains with you the client. Our disclaimer should be fully visible upon distribution to any third party. Distribution without our disclaimer indemnifies Slater against all claims, actions, damages, liabilities, costs and expenses (including but not limited to reasonable legal costs and expenses) incurred by us and arising out of our connection with any action, claim or proceeding brought by any third party in connection with the services provided by us to you.</li>
      <li>All tax and financial statements are prepared by us to industry standard according to the Institute of Chartered Accountants. These include SES-2 for financial statements, CS-1 for compilation, and other chartered accountancy specified documented standards unless otherwise specified. Taxation services relating to income tax and returns are prepared for the financial year indicated, and a new agreement for a new year applies. Financial statements and compilations are performed on an ongoing and annual basis, or on a one-off basis. Professional standards apply to all services. Slater include excellent customer service as part of standard operating procedures. Services beyond the agreed services are billed additionally and may require a new Agreement. Each year we will send you a fresh YearEnd Agreement. These are categorised as follows:</li>
      <li>YearEnd Agreement – Financials and Tax Returns</li>
      <li>EveryDay Jobs – GST, FBT and General Compliance</li>
      <li>Business Development Jobs</li>
      <li>If you change your selection we’ll send you a new YearEnd Agreement for you to sign and send back to us.</li>
      </ol></li>

      <li><strong>Responsibility for error detection remains with the client</strong>
      <ol>
      <li>Compilation services are separated from audit services. This means that compilation doesn’t result in an audit or review opinion, and doesn’t provide any form of assurance. Any inaccuracies, including internal company quality control or internal processing, are the responsibility of the client. Any failure to supply all relevant records and information is the responsibility of the client.</li>
      </ol></li>

      <li><strong>Matters of Inaccuracy Detected</strong>
      <ol>
      <li>Slater are obliged and required by industry standard, set by the Institute of Chartered Accountants, to act upon any inaccuracies or inconsistencies detected in the normal course of providing the services agreed upon.</li>
      <li>Slater is not engaged to detect, investigate, or discover any internal control weaknesses, errors, illegal acts or other irregularities, including without limitation fraud, or non-compliance with laws or regulations. Slater will inform the client of any such matters that come to our attention.</li>
      <li>If, for any reason, we are unable to complete the compilation of your financial matters within our compilation of your financial information, or we consider the information to be misleading, we may refer to such matters in our report, or we may determine, at our sole discretion, not to issue a report.</li>
      <li>Slater often refers complex or specialist matters to specialist tax advisors and lawyers, and reserves the right to contact such partners at Slater discretion and the customer undertakes to cover all costs.</li>
      </ol></li>

      <li><strong>Income Tax Matters (where tax services are with your prior agreement)</strong>
      <ol>
      <li>Slater will use our judgment in resolving questions where tax law is unclear or where there may be conflicts between taxing authorities’ interpretations of the law and other supportable positions. Unless otherwise instructed by you, we’ll resolve such questions in your favour whenever possible.</li>
      <li>Income tax law imposes a penalty if a taxpayer makes a substantial understatement of its tax liability. Before completing preparation of the return(s), we’ll discuss with you the tax positions that may significantly increase the risk of exposure to penalties.</li>
      <li>Management is responsible for the proper recording of transactions in the books of account, for the safeguarding of assets, and for the substantial accuracy of financial records. Management also has final responsibility for the income tax return(s) and, therefore, the appropriate official should review the return(s) carefully before an authorised officer signs and files.</li>
      <li>All returns are subject to examination by the taxing authorities. In the event of such examination, you may be requested to produce documents, records, or other evidence to substantiate the items of income and deduction shown on the tax return. If an examination occurs, we will be available, upon request, to assist or represent you. Such services are not included in our fee for the preparation of the return(s).</li>
      </ol></li>

      <li><strong>Internal Revenue Department Authority to Act</strong>
      <ol>
      <li>This agreement authorises Slater Chartered Accountants to act as your agent in all dealings with the Inland Revenue Department (‘IRD’) in respect of income tax matters including filing of your income tax return once all relevant information has been received. We will not be liable for any penalties imposed by the IRD as a result of your failing to supply us with accurate, complete and relevant information. We will be available at any time to advise you of any financial matters you may require our opinion on, within the scope of the agreed upon services. We will also report on any matters that come to our attention in the process of completing your financial statements and tax returns that will be of material benefit to you. We will provide any other services as are agreed upon between us from time to time.</li>
      </ol></li>

      <li><strong>Independence</strong>
      <ol>
      <li>Independence is not a legal requirement for a compilation engagement. Where Slater are aware that they are not independent of the client entity, this fact will be stated in reports generated.However, reviews will not be conducted to ascertain whether Slater are, or are not, independent of the client entity.</li>
      </ol></li>

      <li><strong>Disclaimer</strong>
      <ol>
      <li>We have compiled all our work for you including YearEnd, EveryDay and Business Development work based on information provided which has not been subjected to an audit or review engagement. Accordingly we do not accept any responsibility for the reliability, accuracy, completeness of the compiled financial information contained in the financial statements or other work produces. Nor do we accept any liability of any kind whatsoever, including liability by reason of negligence, to any person for losses incurred as a result of placing reliance on these financial statements.”</li>
      </ol></li>

      <li><strong>Terms of Trade</strong>
      <ol>
      <li>All invoices are payable in advance. If the invoice is not paid within 30 days after the due date and the work – whether it be GST, Tax work, Financial Statements or any other type of work) has been performed by us and is completed for you, our debt recovery agency charge you collection costs of at least 25% of the unpaid portion of the price, but not less than $100.00. Where the total agency, legal and other costs arising from recovery of any amount owing exceeds the debt recovery fee charged, our agent is also entitled to recover such additional costs from you. This clause is intended to be for the benefit of and be enforceable by our debt recovery agency under the Contracts (Privacy) Act 1982.</li>
      <li>You agree that the terms of trade are strictly settlement on or before the start of work for you and Slater Chartered Accountants will be entitled to charge interest at the rate of 24% per annum (2 % per month) on all amounts that have not been paid in full by the commencement of the work done for you, or from due date of the invoice should forward calculation of the invoice not be practical. These interest costs are additional to any collection costs.</li>
      <li>You agree to indemnify Slater Chartered Accountants against all costs, whether commission, legal fee or otherwise, incurred by you or your duly authorised agents relating to any moneys, goods and services that may be outstanding from time to time pursuant to the terms of this agreement. Any costs whatsoever not recoverable from the invoiced entity will be personally recoverable from the instructing person requesting the work/invoice.</li>
      <li>Any costs incurred in the collection of payment will be your responsibility.</li>
      </ol></li>
      </ol>
      <ol>
      <li><strong>Privacy Policy</strong>
      <ol>
      <li>Slater Chartered Accountants believes the responsible use of personal information is critical to enable the Internet to reach its potential as a tool for personal and professional activities. In line with the Privacy Act 1993, Slater Chartered Accountants is committed to respecting your privacy. You can visit www.slater.co.nz without telling us who you are or revealing personal information.</li>
      </ol></li>

      <li><strong>Purpose of information collection</strong>
      <ol>
      <li>You may choose to provide us with information such as your name, home or business address and e-mail address. For example you may choose to purchase a product directly from our Slater Chartered Accountants New Zealand website, subscribe to our services, or you can request specific information on products or services. When you give us personal information we will let you know how we will use it. We will also take appropriate steps to protect the information you share with us, including using appropriate technology for credit card data used in electronic commerce transactions.</li>
      </ol></li>

      <li><strong>How information is used?</strong>
      <ol>
      <li>When Slater Chartered Accountants receives information about your session or about you, we may use it for research or to improve our site and services. We will ask if you want us to use that information to continue contact with you – for example, to send you new product information or notification of special offers. If you do not want us to contact you, you can choose to ‘opt out’.</li>
      <li>In general on www.slater.co.nz IP addresses (the Internet address of a computer) are logged to track a user’s session while the user remains anonymous. We analyse this data for trends, such as which parts of our site users are visiting and how long they spend there. In general, we do not link your IP addresses to anything personally identifiable to you. If we wish to do this we will give you the opportunity to ‘opt out’.</li>
      </ol></li>

      <li><strong>Use of ‘cookies’</strong>
      <ol>
      <li>Some Slater Chartered Accountants web pages use ‘cookies’, which are small files that may be placed on your hard disk for record-keeping purposes. A cookie allows us to remember who you are. Cookies can make your subsequent visits to the web page simpler and more seamless. You can set your browser to notify you when you receive a cookie, giving you the chance to decide whether or not to accept it. (However, by not accepting cookies, some web pages may not display properly or you may not be permitted to access certain information.)</li>
      </ol></li>

      <li><strong>To stop information</strong>
      <ol>
      <li>If you have any questions or comments about our privacy practices, would like to stop receiving information from Slater Chartered Accountants, or would like to correct or update information provided to Slater Chartered Accountants via our website, please contact us by either clicking on the ‘contact us’ button on every page on www.slater.co.nz, or by sending an e-mail to our lead partner at dinu@slater.co.nz. </li>
      </ol></li>

      </ol>
  </main>
</template>

<script>
export default {
  created(){
    document.title = 'Privacy & Terms - Slater'
  },
}
</script>

<style scoped>
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}
li {
  padding: 6px 0;
}

h2 {
  margin-bottom: 14px;
  margin-top: 122px;
}


ol > li::before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

ol > li::before {
    content: counters(item, ".") " ";
}

li ol > li {
  margin: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

li {
  padding: 6px 0;
}

main {
  margin: 100px auto;max-width:1000px;color:#213B55;padding:10px;
}

.header-title {
  margin-bottom:30px;font-size:72px;font-weight:600;
}

@media only screen and (max-width: 768px) {
  .header-title {
    margin-bottom:50px;
    font-size:50px;
    text-align: center;
  }

  main {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .header-title {
    margin-bottom:15px;
    font-size:50px;
    text-align: center;
  }

  main {
    margin-top: 30px;
  }
}
</style>
