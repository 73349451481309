<template>
  <main style="display:flex;flex-direction:column;">

    <div class="section-contact" style="">

      <div class="section-form" style="">

        <div style="margin-bottom:50px;">
          <div style="font-size:38px;font-weight:600;color:#213b55;">Contact Us</div>
          <div style="color:#213b55;margin-bottom:20px;margin-top:20px;font-size:18px;">Fill out the form below to send us a message.</div>
        </div>

        <div style="display:flex;flex-direction:column;">
          <input v-model="form.name" type="text" placeholder="Full Name" style="border-radius:8px;width:100%;color:#213b55;font-size:15px;border: 1px solid rgb(213, 216, 218);height:50px;padding:5px 10px;"/>
          <input v-model="form.email" type="text" placeholder="Email" style="margin-top:20px;border-radius:8px;width:100%;color:#213b55;font-size:15px;border: 1px solid rgb(213, 216, 218);height:50px;padding:5px 10px;"/>
          <input v-model="form.phone" type="text" placeholder="Phone" style="margin-top:20px;border-radius:8px;width:100%;color:#213b55;font-size:15px;border: 1px solid rgb(213, 216, 218);height:50px;padding:5px 10px;"/>
          <textarea v-model="form.message" placeholder="How can we help you?" style="resize:vertical;min-height:100px;max-height:200px;margin-top:20px;border-radius:8px;width:100%;color:#213b55;font-size:15px;border: 1px solid rgb(213, 216, 218);padding:5px 10px;"></textarea>
          <div style="color:#213b55;margin-top:20px;font-size:18px;">Submitting this for you agree to our <a href="/terms" target="_blank" style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:18px;">Privacy & Terms</a></div>

          <div style="margin-top:20px;margin-bottom:20px;">
            <vue-recaptcha
            v-show="showRecaptcha"
            :sitekey="capkey"
            hl="tr"
            @verify="onVerify"
            @expired="onExpired"
            ref="vueRecaptcha"
            theme="light"
            size="normal"
            />
          </div>

          <div @click.prevent="send" style="max-width:200px;font-size:15px;margin-right:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
            <div>Send</div>
          </div>
        </div>
      </div>

      <div class="section-info" style="">

        <div style="display:flex;flex-direction:column;">
          <div style="font-size:38px;font-weight:600;color:#213b55;">Call Us</div>
          <div style="color:#213b55;margin-bottom:10px;margin-top:20px;font-size:18px;">Call us anytime by phone on</div>
          <a href="tel:078389700" style="margin-bottom:30px;text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:18px;">07 838 9700</a>
        </div>

        <div style="display:flex;flex-direction:column;">
          <div style="font-size:38px;font-weight:600;color:#213b55;">Support</div>
          <div style="color:#213b55;margin-bottom:10px;margin-top:20px;font-size:18px;">If you have any concerns contact our head partner Dinu directly</div>
          <a href="tel:078389701" style="margin-bottom:30px;text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:18px;">07 838 9701</a>
        </div>

        <div style="display:flex;flex-direction:column;">
          <div style="font-size:38px;font-weight:600;color:#213b55;">General Questions</div>
          <div style="color:#213b55;margin-bottom:10px;margin-top:20px;font-size:18px;">Have a general question for us?</div>
          <div style="color:#213b55;margin-bottom:30px;font-size:18px;">Contact reception at <a href="mailto:christine@slater.co.nz" style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:18px;">christine@slater.co.nz</a></div>
        </div>

      </div>

    </div>

    <div class="section-location" style="">

      <div class="section-text" style="">
        <div style="font-size:38px;font-weight:600;color:#213b55;">Location</div>
        <div style="color:#213b55;margin-bottom:10px;margin-top:20px;font-size:18px;">Level 1, 127 Collingwood Street</div>
        <div style="color:#213b55;font-size:18px;">Hamilton 3204</div>
        <div style="color:#213b55;margin-bottom:10px;margin-top:20px;font-size:18px;">Free parking at rear of the building</div>
      </div>

      <div class="section-map" style="">
        <GoogleMap
              :center="center"
              :zoom="18"
              style="width: 100%; height: 400px"
              :api-key="mapKey"
              map-type-id="roadmap"
              >
              <Marker
                :options="{ position: center }"
              />
          </GoogleMap>
      </div>

    </div>

  </main>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';
import axios from 'axios'
import { rejectError } from '@/helpers'
import { GoogleMap, Marker } from "vue3-google-map";
export default {
  components: {
	  vueRecaptcha,
    GoogleMap,
    Marker
  },
  data(){
    return {
      center: { lat: -37.7904910436618, lng: 175.2802241175592 },
      capkey: process.env.VUE_APP_CAPKEY,
      mapKey: process.env.VUE_APP_MAPKEY,
      sendClicked: false,
      form: {
        name: null,
        email: null,
        phone: null,
        message: null,
        verified: false,
        client: process.env.VUE_APP_CLIENT_EMAIL,
        contact: process.env.VUE_APP_CLIENT_NAME,
        domain: process.env.VUE_APP_DOMAIN
      }
    }
  },
  created(){
    document.title = 'Contact - Slater'
  },
  methods: {
    send(){
      if(this.sendClicked){
        console.log('user spamming')
      } else {
        if(this.form.verified) {
          this.sendClicked = true
          axios.post(`${process.env.VUE_APP_URLAPI}/emails/form/submit`, this.form, { timeout: process.env.VUE_APP_TIMEOUT })
          .then(res => {
            this.form.verified = false
            this.resetRecaptcha()
            this.form.name = null
            this.form.email = null
            this.form.phone = null
            this.form.message = null
            this.sendClicked = false
            this.$toasted.error('Message sent', {position: "bottom-center", duration: 10000})
          })
          .catch(err => {
            this.sendClicked = false
            this.$toasted.error(rejectError(err), {position: "bottom-center", duration: 5000})
          })

          } else {
            this.$toasted.error('Beep boop?', {position: "bottom-center", duration: 5000})
          }
      }
    },
    onVerify(){
      this.form.verified = true
    },
    onExpired(){
      this.form.verified = false
    },
    resetRecaptcha () {
      this.$refs.vueRecaptcha.reset()
    }
  }
}
</script>

<style scoped>

.section-contact {
  display:flex;margin-right:2vw;margin-top:100px;
}

.section-form {
  width:30vw;margin-left:9vw;display:flex;flex-direction:column;
}

.section-info {
  width:20vw;margin-left:9vw;display:flex;flex-direction:column;
}

.section-location {
  margin-bottom:100px;margin-top:100px;display:flex;margin-left:9vw;margin-right:2vw;
}

.section-text {
  width:30vw;display:flex;flex-direction:column;justify-content:center;
}

.section-map {
  margin-left:2vw;width:40vw;
}

@media only screen and (max-width: 768px) {
  .section-contact {
    padding:20px;
    margin-top:50px;
  }

  .section-form {
    width:100%;
    margin-left:0;

  }

  .section-info {
    width:100%;
    margin-left:20px;
    margin-top:100px;
  }

  .section-location {
    flex-direction: column;
    margin-left:0;
    margin-right:0;
    padding:20px;
  }

  .section-text {
    width:100%;
    text-align: center;
  }

  .section-map {
    width:100%;
    margin-left: 0;
    margin-top:100px;
  }

}

@media only screen and (max-width: 480px) {
  .section-contact {
    flex-direction: column;
    padding:20px;
    margin-top:50px;
  }

  .section-form {
    width:100%;
    margin-left:0;

  }

  .section-info {
    width:100%;
    margin-left:0;
    margin-top:100px;
  }

  .section-location {
    flex-direction: column;
    margin-left:0;
    margin-right:0;
    padding:20px;
  }

  .section-text {
    width:100%;
  }

  .section-map {
    width:100%;
    margin-left: 0;
    margin-top:100px;
  }

}
</style>
