<template>
  <main style="display:flex;flex-direction:column;">

    <div v-if="loaded && post && post.content" class="post" style="">

      <div class="title">{{ post.title }}</div>

      <div class="subheading" style="display:flex;margin-top:5px;">
        <div style="margin-right:5px;">{{ post.createdAt | formatDate }}</div>
        <div>- {{ post.time }} min read</div>
      </div>

      <img :src="post.thumb" class="thumb" style=""/>


      <div v-for="(block, index) in post.content.blocks" :key="`block-${index}`" style="margin-top:2em">
          <h1 v-if="block.type === 'header'" v-html="block.data.text"></h1>
          <p v-if="block.type === 'paragraph'" v-html="block.data.text"></p>

      </div>


    </div>

    <!-- // cta -->
    <section style="margin: 200px 2vw;display:flex;flex-direction:column;background-color:#bbf3fd;justify-content:center;align-items:center;">
      <div style="font-size:51px;font-weight:600;color:#213B55;margin-top:50px;text-align:center;padding:10px;">Experience the Slater difference</div>
      <div style="font-weight:27px;color:#213B55;margin-top:10px;">Too busy to come to us? we can come to you!</div>

      <a href="/contact" style="font-weight:500;cursor:pointer;margin-top:50px;margin-bottom:50px;background-color:#213B55;color:#fff;padding: 5px 20px;max-width:200px;font-size:20px;border-radius:4px;">
        <div>Lets talk</div>
      </a>

    </section>
    <!-- cta // -->

  </main>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      post: {},
      loaded: false
    }
  },
  created(){
    const id = this.$route.params.id

    var BASE_URL = process.env.VUE_APP_URLCMS
    axios.get(`${BASE_URL}/posts/api/item/${id}`)
      .then(res => {
        this.post = res.data
        this.loaded = true
        document.title = `${this.post.title} - Slater`
      })
      .catch(err => console.log(err))
  }
}
</script>

<style scoped>

.title {
  font-family: 'Charter', sans-serif;
  font-size: 48px;
  line-height: 60px;
}

h1 {
  font-family: 'Shone', sans-serif;
  font-size: 30px;
  line-height: 36px;
}

div >>> h1 {
  font-family: 'Shone', sans-serif;
  font-size: 30px;
  line-height: 36px;
}

div >>> .ce-paragraph {
  font-family: 'Charter', serif;
  line-height: 32px;
  font-size: 21px;
  color: rgb(41, 41, 41);
}

.subheading {
  color: rgba(117, 117, 117, 0.5);
  margin-bottom:20px;
  font-family: 'Shone', sans-serif;
  font-size: 15px;
  line-height: 1.22;
}

p {
  font-family: 'Charter', serif;
  line-height: 32px;
  font-size: 21px;
  color: rgb(41, 41, 41);
}

div >>> a {
  text-decoration: underline;
}

.post {
  margin: 100px auto;min-width:650px;max-width:650px;
}

.thumb {
  margin: 50px 0;object-fit:cover;width:100%;height:400px;
}

@media only screen and (max-width: 480px) {
  .post {
    min-width: 100%;
    padding:20px;
    margin:50px auto;
  }

  div >>> h1 {
    font-size: 22px;
    line-height: 28px;
  }

  p {
    line-height: 28px;
    font-size: 18px;
  }

  .title {
    font-size: 34px;
    line-height: 42px;
  }

  .subheading {
    font-size: 14px;
    line-height: 1.22;
  }

  .thumb {
    height:200px;
  }
}
</style>
