<template>
  <main>

    <div style="margin:100px 0;display:flex;flex-direction:column;align-items:center;">
      <div class="header-title" style="">Pricing</div>
      <div style="margin-bottom:30px;color:#213B55;font-size:27px;">easy as</div>

      <div class="points" style="">

        <div style="display:flex;align-items:center;">
          <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
          <div style="margin-left:20px;">Guranteed no hidden costs</div>
        </div>

        <div class="points-item" style="">
          <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
          <div style="margin-left:20px;opacity:0.9;">On time completion</div>
        </div>

        <div class="points-item" style="">
          <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
          <div style="margin-left:20px;">Reduce your tax</div>
        </div>

      </div>
    </div>


    <!-- //pricing table business-->
    <section style="margin-left:2vw;margin-right:2vw;">

      <div style="display:flex;background-color:#bbf3fd;align-items:center;justify-content:center;padding: 60px 0 0;">
        <div style="align-self:center;font-size:32px;color:#213B55;font-weight:600;">Business Owners</div>
      </div>

      <div class="table blue" style="">

        <div class="table-item" style="">
          <div style="align-self:center;font-size:21px;color:#213B55;font-weight:600;">Non-GST Registered</div>
          <div style="align-self:center;font-size:72px;color:#213B55;font-weight:600;">$90<span style="font-size:32px;">.00</span></div>
          <div style="align-self:center;color:#213B55;margin-top:10px;">NZD per month excluding GST</div>
          <div style="align-self:center;color:#213B55;text-align:center;margin-top:20px;padding: 0 20px;">Non-GST regestered businesses, sole traders & partnerships</div>

          <div style="margin-top:50px;margin-bottom:50px;font-size:15px;margin-left:20px;">

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Financal statements</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Up to 3 tax returns</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax minimization</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax planning & management</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Unlimited advice</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <svg height="13px" viewBox="0 0 329.26933 329" width="13px" xmlns="http://www.w3.org/2000/svg">
                <g fill="rgba(33, 59, 85, 0.5)">
                  <path d="m21.339844 329.398438c-5.460938 0-10.925782-2.089844-15.082032-6.25-8.34375-8.339844-8.34375-21.824219 0-30.164063l286.589844-286.59375c8.339844-8.339844 21.824219-8.339844 30.164063 0 8.34375 8.339844 8.34375 21.824219 0 30.164063l-286.589844 286.59375c-4.183594 4.179687-9.621094 6.25-15.082031 6.25zm0 0"/><path d="m307.929688 329.398438c-5.460938 0-10.921876-2.089844-15.082032-6.25l-286.589844-286.59375c-8.34375-8.339844-8.34375-21.824219 0-30.164063 8.339844-8.339844 21.820313-8.339844 30.164063 0l286.589844 286.59375c8.34375 8.339844 8.34375 21.824219 0 30.164063-4.160157 4.179687-9.621094 6.25-15.082031 6.25zm0 0"/>
                </g>
              </svg>
              <div style="margin-left:20px;color:#213B55;opacity:0.5;">GST Returns</div>
            </div>

          </div>

          <a href="/contact" style="font-size:17px;margin-right:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
            <div>Lets talk</div>
          </a>

        </div>

        <div class="table-item" style="">
          <div style="align-self:center;font-size:21px;color:#213B55;font-weight:600;">GST Registered</div>
          <div style="align-self:center;font-size:72px;color:#213B55;font-weight:600;">$115<span style="font-size:32px;">.00</span></div>
          <div style="align-self:center;color:#213B55;margin-top:10px;">NZD per month excluding GST</div>
          <div style="align-self:center;color:#213B55;text-align:center;margin-top:20px;padding: 0 20px;">GST registered bussinesses that do their own GST returns</div>

          <div style="margin-top:50px;margin-bottom:50px;font-size:15px;margin-left:20px;">

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Financal statements</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Up to 3 tax returns</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax minimization</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax planning & management</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Unlimited advice</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <svg height="13px" viewBox="0 0 329.26933 329" width="13px" xmlns="http://www.w3.org/2000/svg">
                <g fill="rgba(33, 59, 85, 0.5)">
                  <path d="m21.339844 329.398438c-5.460938 0-10.925782-2.089844-15.082032-6.25-8.34375-8.339844-8.34375-21.824219 0-30.164063l286.589844-286.59375c8.339844-8.339844 21.824219-8.339844 30.164063 0 8.34375 8.339844 8.34375 21.824219 0 30.164063l-286.589844 286.59375c-4.183594 4.179687-9.621094 6.25-15.082031 6.25zm0 0"/><path d="m307.929688 329.398438c-5.460938 0-10.921876-2.089844-15.082032-6.25l-286.589844-286.59375c-8.34375-8.339844-8.34375-21.824219 0-30.164063 8.339844-8.339844 21.820313-8.339844 30.164063 0l286.589844 286.59375c8.34375 8.339844 8.34375 21.824219 0 30.164063-4.160157 4.179687-9.621094 6.25-15.082031 6.25zm0 0"/>
                </g>
              </svg>
              <div style="margin-left:20px;color:#213B55;opacity:0.5;">GST Returns</div>
            </div>

          </div>

          <a href="/contact" style="font-size:17px;margin-right:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
            <div>Lets talk</div>
          </a>

        </div>

        <div class="table-item" style="">
          <div style="align-self:center;font-size:21px;color:#213B55;font-weight:600;">GST Returns</div>
          <div style="align-self:center;font-size:72px;color:#213B55;font-weight:600;">$155<span style="font-size:32px;">.00</span></div>
          <div style="align-self:center;color:#213B55;margin-top:10px;">NZD per month excluding GST</div>
          <div style="align-self:center;color:#213B55;text-align:center;margin-top:20px;padding: 0 20px;">GST registered businesses that want GST returns done by us</div>

          <div style="margin-top:50px;margin-bottom:50px;font-size:15px;margin-left:20px">

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Financal statements</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Up to 3 tax returns</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax minimization</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax planning & management</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Unlimited advice</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">GST Returns</div>
            </div>

          </div>

          <a href="/contact" style="font-size:17px;margin-right:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
            <div>Lets talk</div>
          </a>

        </div>


      </div>
    </section>
    <!-- pricing table // -->



    <!-- //pricing table property-->
    <section style="margin-left:2vw;margin-right:2vw;margin-top:100px;">

      <div style="display:flex;background-color:#6fefc1;align-items:center;justify-content:center;padding: 60px 0 0;">
        <div style="align-self:center;font-size:32px;color:#213B55;font-weight:600;">Property Investors</div>
      </div>

      <div class="table green" style="">

        <div class="table-item" style="">
          <div style="align-self:center;font-size:21px;color:#213B55;font-weight:600;">One Property</div>
          <div style="align-self:center;font-size:72px;color:#213B55;font-weight:600;">$75<span style="font-size:32px;">.00</span></div>
          <div style="align-self:center;color:#213B55;margin-top:10px;">NZD per month excluding GST</div>
          <div style="align-self:center;color:#213B55;text-align:center;margin-top:20px;padding: 0 20px;">Investors with one property</div>

          <div style="margin-top:50px;margin-bottom:50px;font-size:15px;margin-left:20px;">

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Financal statements</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Up to 3 tax returns</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax minimization</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax planning & management</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Unlimited advice</div>
            </div>

          </div>

          <a href="/contact" style="font-size:17px;margin-right:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
            <div>Lets talk</div>
          </a>

        </div>

        <div class="table-item" style="">
          <div style="align-self:center;font-size:21px;color:#213B55;font-weight:600;">Two - Three Properties</div>
          <div style="align-self:center;font-size:72px;color:#213B55;font-weight:600;">$90<span style="font-size:32px;">.00</span></div>
          <div style="align-self:center;color:#213B55;margin-top:10px;">NZD per month excluding GST</div>
          <div style="align-self:center;color:#213B55;text-align:center;margin-top:20px;padding: 0 20px;">Investors with two - three properties</div>

          <div style="margin-top:50px;margin-bottom:50px;font-size:15px;margin-left:20px;">

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Financal statements</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Up to 3 tax returns</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax minimization</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax planning & management</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Unlimited advice</div>
            </div>

          </div>

          <a href="/contact" style="font-size:17px;margin-right:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
            <div>Lets talk</div>
          </a>

        </div>

        <div class="table-item" style="">
          <div style="align-self:center;font-size:21px;color:#213B55;font-weight:600;">Four Plus Properties</div>
          <div style="align-self:center;font-size:72px;color:#213B55;font-weight:600;">$115<span style="font-size:32px;">.00</span></div>
          <div style="align-self:center;color:#213B55;margin-top:10px;">NZD per month excluding GST</div>
          <div style="align-self:center;color:#213B55;text-align:center;margin-top:20px;padding: 0 20px;">Investors with four or more properties</div>

          <div style="margin-top:50px;margin-bottom:50px;font-size:15px;margin-left:20px">

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Financal statements</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Up to 3 tax returns</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax minimization</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Tax planning & management</div>
            </div>

            <div style="display:flex;margin-bottom:10px;align-items:center;">
              <img height="15px" width="15px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213B55;">Unlimited advice</div>
            </div>

          </div>

          <a href="/contact" style="font-size:17px;margin-right:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 20px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
            <div>Lets talk</div>
          </a>

        </div>


      </div>
    </section>
    <!-- pricing table // -->


    <!-- // cta -->
    <section style="margin: 200px 2vw;display:flex;flex-direction:column;background-color:#bbf3fd;justify-content:center;align-items:center;">
      <div style="font-size:51px;font-weight:600;color:#213B55;margin-top:50px;text-align:center;padding:10px;">Experience the Slater difference</div>
      <div style="font-weight:27px;color:#213B55;margin-top:10px;">Too busy to come to us? we can come to you!</div>

      <a href="/contact" style="font-weight:500;cursor:pointer;margin-top:50px;margin-bottom:50px;background-color:#213B55;color:#fff;padding: 5px 20px;max-width:200px;font-size:20px;border-radius:4px;">
        <div>Lets talk</div>
      </a>

    </section>
    <!-- cta // -->

  </main>
</template>

<script>
export default {
  created(){
    document.title = 'Pricing - Slater'
  },
}
</script>

<style scoped>

.header-title {
  margin-bottom:30px;color:#213B55;font-size:72px;font-weight:600;
}

.points {
  display:flex;font-size:18px;color:#213B55;
}

.points-item {
  display:flex;margin-left:30px;align-items:center;
}

.table {
  display:flex;padding:70px 100px 150px;
}

.table-item {
  padding:50px 30px;width:25vw;display:flex;flex-direction:column;background-color:#fff;
}

.blue {
  background-color:#bbf3fd;
}

.green {
  background-color: #6fefc1;
}

.table-item:nth-child(2),
.table-item:nth-child(3),
.table-item:nth-child(5),
.table-item:nth-child(6) {
  margin-left:30px;
}

@media only screen and (max-width: 768px) {
  .header-title {
    margin-bottom:15px;
    font-size:50px;
  }

  .points {
    flex-direction: column;
  }

  .points-item {
    margin-left:0;
  }

  .table {
    flex-direction: column;
    padding:70px 20px 50px;
  }

  .table-item {
    width:100%;
    margin-bottom:50px;
  }

  .table-item:nth-child(2),
  .table-item:nth-child(3),
  .table-item:nth-child(5),
  .table-item:nth-child(6) {
    margin-left:0;
  }


}

@media only screen and (max-width: 480px) {
  .header-title {
    margin-bottom:15px;
    font-size:50px;
  }

  .points {
    flex-direction: column;
  }

  .points-item {
    margin-left:0;
  }

  .table {
    flex-direction: column;
    padding:70px 20px 50px;
  }

  .table-item {
    width:100%;
    margin-bottom:50px;
  }

  .table-item:nth-child(2),
  .table-item:nth-child(3),
  .table-item:nth-child(5),
  .table-item:nth-child(6) {
    margin-left:0;
  }


}
</style>
