<template>
  <footer>

    <a href="/">
      <img style="height:48px;width:48px;" src="https://cdn.side.nz/v/2f9a8bcc-b51f-4a18-be2f-3626fee11460.svg">
    </a>

    <div style="display:flex;flex-direction:column;">

      <div class="footer-row" style="">
        <div class="footer-col" style="">
          <div style="margin-bottom:20px;color:rgb(33, 59, 85);">About Slater</div>
          <a href="/partners">
            <div style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Partners</div>
          </a>

        <a href="/testimonials">
          <div style="margin-top:20px;text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Testimonials</div>
        </a>
        </div>

        <div class="footer-col">
          <div style="margin-bottom:20px;color:rgb(33, 59, 85);">Contact</div>
          <a href="tel:078389700">
            <div style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">07 838 9700</div>
          </a>

        <a href="/contact">
          <div style="margin-top:20px; text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Email us</div>
        </a>
        </div>

        <div class="footer-col" style="">
          <div style="margin-bottom:20px;color:rgb(33, 59, 85);">How we can help</div>
          <a href="/blog">
          <div style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Blog</div>
        </a>

        <a href="/pricing">
        <div style="margin-top:20px; text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Pricing</div>
      </a>
        </div>

      </div>

      <div style="padding-top:43px;display:flex;border-top: 1px solid #d6dade;">

        <div style="display:flex;flex-direction:column;width:30vw">
          <div style="color:rgb(33, 59, 85);font-size:15px;">
            © {{new Date().getFullYear()}} Slater Charted Accountants Limited.
          </div>

          <div style="display:flex;margin-top:40px;">
            <a href="/terms">
              <div style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:500;font-size:15px;">Privacy & Terms</div>
            </a>

          </div>
        </div>

        <div class="social-links" style="">
          <a href="https://www.facebook.com/slaternz/" target="_blank">
            <div style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Facebook</div>

          </a>

          <a class="social-item" href="https://twitter.com/SlatersNZ/" target="_blank" style="">
            <div style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Twitter</div>

          </a>

          <a class="social-item" href="https://www.instagram.com/slater_chartered_accountants/" target="_blank" style="">
            <div style="text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Instagram</div>

          </a>

        </div>

      </div>

    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>

footer {
  display:flex;flex-direction:column;padding: 0 150px 60px;margin-top:50px;
}

.social-links {
  display:flex;width:30vw;margin-left:20vw;justify-content: space-around;margin-top:20px;
}

.social-item {
  margin-left:20px;
}

.footer-row {
  display:flex;margin-top:50px;padding-bottom:83px;
}

.footer-col {
  display:flex;flex-direction:column;width:25vw;
}

@media only screen and (max-width: 768px) {
  footer {
    padding: 0 20px 60px;
  }

  .social-links {
    flex-direction: column;
    margin-top:0;
  }

  .social-item {
    margin-left:0;
  }

  .footer-col {
    width:100%;
    margin-top:30px;
  }
}

@media only screen and (max-width: 480px) {
  footer {
    padding: 0 20px 60px;
  }

  .social-links {
    flex-direction: column;
    margin-top:0;
  }

  .social-item {
    margin-left:0;
  }

  .footer-row {
    flex-direction: column;
  }

  .footer-col {
    width:100%;
    margin-top:30px;
  }
}

</style>
