<template>
  <div style="height: calc(100vh - 72px);position:absolute;top:72px;left:0;z-index:200;width:100%;overflow:hidden auto;">

    <div style="color:#213b55;padding: 20px;height:100%;display:flex;flex-direction:column;background-color:#fff;">

      <div style="display:flex;flex-direction:column;margin-top:10px;">
        <router-link :to="{ name: 'Reviews' }" @click.native="toggleMenu" style="margin-bottom:20px;text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:32px;">Testimonials</router-link>
        <router-link :to="{ name: 'Partners' }" @click.native="toggleMenu" style="margin-bottom:20px;text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:32px;">Partners</router-link>
        <router-link :to="{ name: 'Pricing' }" @click.native="toggleMenu" style="margin-bottom:20px;text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:32px;">Pricing</router-link>
        <router-link :to="{ name: 'Blog' }" @click.native="toggleMenu" style="margin-bottom:20px;text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:32px;">Blog</router-link>
        <router-link :to="{ name: 'Contact' }" @click.native="toggleMenu" style="margin-bottom:20px;text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:32px;">Contact</router-link>
      </div>

        <div style="display:flex;flex-direction:column;margin-top:20px;">
          <a href="tel:078389700" style="font-size:15px;border-radius:4px;font-weight:600;height:40px;padding:5px 10px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#50dcaa;cursor:pointer;">
            <div>07 838 9700</div>
          </a>

          <a href="https://login.xero.com/identity/user/login" target="_blank" style="margin-top:20px;font-size:15px;border: 2px solid rgba(33, 59, 85, 0.3);border-radius:4px;font-weight:600;height:40px;padding:5px 10px;display:flex;justify-content:center;align-items:center;color:#183043;background-color:#fff;cursor:pointer;">
            <div>Xero Login</div>
          </a>
        </div>


    </div>



  </div>
</template>

<script>
export default {
  methods: {
    toggleMenu(){
      this.$emit('toggleMenu')
    }
  }
}
</script>

<style scoped>

</style>
