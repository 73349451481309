import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import Toasted from 'vue-toasted'

let app;

app = createApp(App);

app
.use(router)
//.use(Toasted)
app.mount('#app')