<template>
  <main>

    <section style="display:flex;background-color:#fff;align-items:center;justify-content:center;padding: 60px 0 0;">
      <div style="align-self:center;font-size:32px;color:#213B55;font-weight:600;">Blog</div>
    </section>

    <section style="max-width: 1140px;margin:100px auto;width:100%;">
      <div v-if="loaded" class="blog-table" style="">

        <!--blog item-->
        <div v-for="post in posts" :key="post._id" class="blog-container" style="">
          <div class="blog-item" style="height: 425px;position: relative;width: 100%;border: 1px solid #f5f6f6;float: left;margin-bottom: 40px;transition: box-shadow .2s ease-in-out;">

            <router-link :to="{ name: 'Post', params: { id: post._id } }" style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;display: block;height: 100%;width: 100%;z-index: 8;"></router-link>
            <!--image-->
            <div :style="{ 'background-image': 'url(' + post.thumb + ')' }" style="border-radius: 2px 2px 0 0;background-color: #bbf3fd;background-repeat: no-repeat;background-size: cover;background-position: 50%;height: 176px;width: 100%;-o-object-fit: cover;object-fit: cover;"></div>

            <div style="padding: 24px 24px 62px;border-radius: 0 0 2px 2px;">
              <div style="text-transform: uppercase;font-size: 14px;color: #3c3f40;letter-spacing: 0;margin-bottom: 12px;">{{ post.time }} min read</div>
              <div style="font-size: 20px;line-height: 25px;color: #3c3f40;font-weight: 500;">{{ post.title }}</div>
            </div>

            <div style="padding: 0 24px;position: absolute;bottom: 18px;font-size: 16px;line-height: 28px;">{{ post.createdAt | formatDate }}</div>
          </div>

        </div>
        <!--blog item end-->



      </div>

    </section>
    <!-- pricing table // -->



    <!-- // cta -->
    <section style="margin: 200px 2vw;display:flex;flex-direction:column;background-color:#bbf3fd;justify-content:center;align-items:center;">
      <div style="font-size:51px;font-weight:600;color:#213B55;margin-top:50px;text-align:center;padding:10px;">Experience the Slater difference</div>
      <div style="font-weight:27px;color:#213B55;margin-top:10px;">Too busy to come to us? we can come to you!</div>

      <a href="/contact" style="font-weight:500;cursor:pointer;margin-top:50px;margin-bottom:50px;background-color:#213B55;color:#fff;padding: 5px 20px;max-width:200px;font-size:20px;border-radius:4px;">
        <div>Lets talk</div>
      </a>

    </section>
    <!-- cta // -->

  </main>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
        posts: [],
        loaded: false
    }
  },
  created(){
    document.title = 'Blog - Slater'
    var BASE_URL = process.env.VUE_APP_URLCMS
    axios.get(`${BASE_URL}/posts/api/list`)
      .then(res => {
        this.posts = res.data
        this.loaded = true
      })
      .catch(err => console.log(err))
  },
}
</script>

<style scoped>

.blog-table {
  display: flex;flex-wrap: wrap;margin-right: -15px;margin-left: -15px;
}

.blog-item {
  box-shadow: 0 6px 20px 0 rgba(60,63,64,.07);
}

.blog-item:hover {
  box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);
}

.blog-container {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 768px) {
  .blog-table {
    padding:5px;
    margin:0;
  }

  .blog-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .blog-table {
    flex-direction: column;
    padding:5px;
    margin:0;
  }

  .blog-container {
    max-width: 100%;
  }
}
</style>
