<template>
  <main>


  <!-- // second section-->
  <section class="section-item" style="">

      <div class="section-col-2" style="">
        <img style="height:auto;width:300px;object-fit:cover;border-radius:50%;" src="https://cdn.side.nz/v/add57ce6-411d-4377-99e4-0b10b7301381.jpg">
      </div>

      <div class="section-col-1" style="">
        <div style="font-size:38px;font-weight:600;color:#213b55;">Dinu Harry</div>

        <div style="color:#213b55;margin-bottom:30px;margin-top:30px;font-size:21px;">2010 President of the New Zealand Institute of Chartered Accountants. (now CAANZ)</div>

        <div style="color:#213b55;margin-bottom:30px;font-size:21px;">A Chartered Accountant with extensive governance, business, accounting and financial management experience in the public, private and not for profit sectors.</div>

        <div style="color:#213b55;margin-bottom:30px;font-size:21px;">Governance experience includes Chairing Audit, Finance and Risk Committees and overseeing extensive business transformation projects</div>

        <div style="color:#213b55;margin-bottom:30px;font-size:21px;">Extensive networks within the New Zealand Indian and other Ethnic communities, particularly in Auckland</div>

        <div style="color:#213b55;margin-bottom:30px;font-size:21px;">This experience has been gained through my involvement as a chartered accountant and business advisor to a range of clients and through election and\or appointment to a range of entities in a leadership\governance role in the public, private and not for profit sectors.</div>

        <div class="section-contact" style="">
          <a href="tel:078389701" style="margin-top:20px; text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">07 838 9701</a>
          <a class="contact-item" href="mailto:dinu@slater.co.nz" style="margin-top:20px; text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">dinu@slater.co.nz</a>
          <a class="contact-item" href="https://nz.linkedin.com/in/dinu-harry-00970231" target="_blank" style="margin-top:20px; text-decoration:underline;text-underline-offset:2px;text-decoration-color:#13b5ea;font-weight:600;font-size:21px;">Linkedin</a>
        </div>

      </div>

    </section>
    <!--second section // -->

    <!-- // cta -->
    <section style="margin: 200px 2vw;display:flex;flex-direction:column;background-color:#6fefc1;justify-content:center;align-items:center;">
      <div style="font-size:51px;font-weight:600;color:#213B55;margin-top:50px;text-align:center;padding:10px;">Experience the Slater difference</div>
      <div style="font-weight:27px;color:#213B55;margin-top:10px;">Too busy to come to us? we can come to you!</div>

      <a href="/contact" style="font-weight:500;cursor:pointer;margin-top:50px;margin-bottom:50px;background-color:#213B55;color:#fff;padding: 5px 20px;max-width:200px;font-size:20px;border-radius:4px;">
        <div>Lets talk</div>
      </a>

    </section>
    <!-- cta // -->




  </main>
</template>

<script>
export default {
  created(){
    document.title = 'Partners - Slater'
  },
}
</script>

<style scoped>
.section-item {
  margin-top:200px;display:flex;
}

.section-col-1 {
  width:30vw;margin-left:2vw;display:flex;flex-direction:column;
}

.section-col-2 {
  width:30vw;margin-left:10vw;display:flex;align-items:center;
}

.section-contact {
  display:flex;
}

.contact-item {
  margin-left:20px;
}

@media only screen and (max-width: 768px) {
  .section-item {
    flex-direction: column;
    margin-top:100px;
  }

  .section-col-1 {
    width:100%;
    padding:20px;
    margin-left: 0;
  }

  .section-col-2 {
    width:100%;
    margin-bottom:50px;
    justify-content: center;
    margin-left: 0;
  }

  .section-contact {
    flex-direction: column;
  }

  .contact-item {
    margin-left:0;
  }
}

@media only screen and (max-width: 480px) {
  .section-item {
    flex-direction: column;
    margin-top:100px;
  }

  .section-col-1 {
    width:100%;
    padding:20px;
    margin-left: 0;
  }

  .section-col-2 {
    width:100%;
    margin-bottom:50px;
    justify-content: center;
    margin-left: 0;
  }

  .section-contact {
    flex-direction: column;
  }

  .contact-item {
    margin-left:0;
  }
}
</style>
