<template>
  <div id="app" style="max-width:1500px;margin:0 auto;">
    <Header />
    <router-view :key="$route.path" />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  created(){
    document.title = 'Slater Hamilton Chartered Accountants & Business Development'
    console.log('Hello! I was delveloped by Side support@side.nz');
  }
}
</script>

<style>

@font-face {
    font-family: National;
    font-weight: 500;
    font-display: swap;
    src: url('https://cdn.side.nz/v/3438f175-8429-4930-87b1-fc64ffea0292.woff2') format("woff2");
}
@font-face {
    font-family: National;
    font-weight: 600;
    font-display: swap;
    src: url('https://cdn.side.nz/v/29ba2b38-733b-4785-aca6-6c5dcc9f6b5b.woff2') format("woff2");
}

* {
  font-family: National, Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.toasted .primary.error, .toasted.toasted-primary.error {
    background:#ff5555!important;
    font-size: 13px;
    font-weight: bold;
    border-radius: 8px;
    color:#fff;
    height: 47.5px;
    -webkit-box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
    box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
    z-index: 9997;
}

@media only screen and (max-width: 600px) {
  .toasted .primary.error, .toasted.toasted-primary.error {
    border-radius: 0;
  }
}
</style>
