import { createWebHistory, createRouter } from 'vue-router';

import Home from '@/pages/Home'
import Contact from '@/pages/Contact'
import Pricing from '@/pages/Pricing'
import Partners from '@/pages/Partners'
import Reviews from '@/pages/Reviews'
import Blog from '@/pages/Blog'
import Post from '@/pages/Post'
import Legal from '@/pages/Legal'

import NotFound from '@/pages/NotFound'


const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/partners',
      name: 'Partners',
      component: Partners
    },
    {
      path: '/terms',
      name: 'Legal',
      component: Legal
    },
    {
      path: '/blog',
      name: 'Blog',
      component: Blog
    },
    {
      path: '/blog/:id',
      name: 'Post',
      component: Post
    },
    {
      path: '/testimonials',
      name: 'Reviews',
      component: Reviews
    },
    {
      path: '/*',
      name: 'NotFound',
      component: NotFound
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
