<template>
  <div></div>
</template>

<script>
export default {
  created(){
    window.location.href = "/";
  }
}
</script>

<style scoped>
</style>
