<template>
  <main>
      <!-- // hero-->
      <div class="hero-alt">
        <div class="overlay">
        <div class="hero-title" style="">Need accounting advice?</div>
        <div class="hero-subtitle" style="">Free meetings all year with Slater</div>
      </div>
      </div>
      <!--hero // -->

      <!-- // first section-->
      <section class="section-item" style="">

      <div class="section-text" style="">
        <div style="font-size:38px;font-weight:600;color:#213b55;">We take care of the boring stuff</div>

        <div style="color:#213b55;margin-bottom:30px;margin-top:30px;font-size:21px;">Your accounts and finalcials treated with care. So relax!</div>

        <div class="points">

          <div style="display:flex;margin-bottom:20px;">
            <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
            <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">End of year accounts</div>
          </div>

          <div style="display:flex;margin-bottom:20px;">
            <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
            <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">GST returns</div>
          </div>

          <div style="display:flex;margin-bottom:20px;">
            <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
            <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">Advice all year round</div>
          </div>

      </div>

      </div>

      <div class="section-image" style="">
        <img style="height:100%;width:200px;" src="https://cdn.side.nz/v/2f9a8bcc-b51f-4a18-be2f-3626fee11460.svg">
      </div>
    </section>
  <!--fisrt section // -->

  <!-- // second section-->
  <section class="section-item" style="">

      <div class="section-image-mid" style="">
        <img style="height:100%;width:300px;" src="https://cdn.side.nz/v/22e4fb07-e406-4fdd-8fb6-425b89c07ec4.svg">
      </div>

      <div class="section-text-mid" style="">
        <div style="font-size:38px;font-weight:600;color:#213b55;">Accounting in the cloud</div>

        <div style="color:#213b55;margin-bottom:30px;margin-top:30px;font-size:21px;">Slater was one of the first xero only accounting firms in New Zealand</div>

        <div class="points">

          <div style="display:flex;margin-bottom:20px;">
            <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
            <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">In house Xero specialists</div>
          </div>

          <div style="display:flex;margin-bottom:20px;">
            <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
            <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">Free training</div>
          </div>

          <div style="display:flex;margin-bottom:20px;">
            <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
            <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">Make your switch to Xero smooth as</div>
          </div>

        </div>

      </div>

    </section>
    <!--second section // -->


    <!-- // third section-->
    <section class="section-item" style="">

        <div class="section-text" style="">
          <div style="font-size:38px;font-weight:600;color:#213b55;">Registered Professionals</div>

          <div style="color:#213b55;margin-bottom:30px;margin-top:30px;font-size:21px;">Member of a globally connected organisation of the profession's finest</div>

          <div class="points">

            <div style="display:flex;margin-bottom:20px;">
              <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">Over 25 years accredited</div>
            </div>

            <div style="display:flex;margin-bottom:20px;">
              <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">Honest to a tee</div>
            </div>

            <div style="display:flex;margin-bottom:20px;">
              <img height="20px" width="20px" src="https://cdn.side.nz/v/29f05c7a-ef10-4a8d-9edf-4bf1be9d9c8a.svg">
              <div style="margin-left:20px;color:#213b55;font-size:18px;text-align:left;">Uphold the highest ethical, professional and technical standards</div>
            </div>

        </div>

        </div>

        <div class="section-image" style="">
          <img style="height:100%;width:450px;" src="https://cdn.side.nz/v/be0f27a5-96bd-4998-a01a-d32ffdb10c74.svg">
        </div>
      </section>
      <!--third section // -->

      <!-- // cta -->
      <section style="margin: 200px 2vw;display:flex;flex-direction:column;background-color:#6fefc1;justify-content:center;align-items:center;">
        <div style="font-size:51px;font-weight:600;color:#213B55;margin-top:50px;text-align:center;padding:10px;">Experience the Slater difference</div>
        <div style="font-weight:27px;color:#213B55;margin-top:10px;">Too busy to come to us? we can come to you!</div>

        <a href="/contact" style="font-weight:500;cursor:pointer;margin-top:50px;margin-bottom:50px;background-color:#213B55;color:#fff;padding: 5px 20px;max-width:200px;font-size:20px;border-radius:4px;">
          <div>Lets talk</div>
        </a>

      </section>
      <!-- cta // -->




  </main>
</template>

<script>
export default {
  created(){
    document.title = 'Slater Hamilton Chartered Accountants & Business Development'
  },
}
</script>

<style scoped>
/* .hero {
  height:800px;
}

.hero::before {
  content: "";
  background-color:#000;
  opacity: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.hero::after {
  content: "";
  background: url('https://cdn.side.nz/v/6d822032-4563-49e0-8393-afce5caa2176.jpg');
  background-size:cover;
  background-position: 0 -150px;
  background-repeat: no-repeat;
  opacity: 0.7;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
} */

.overlay {
  background-color: rgba(000, 000, 000, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-alt {
  margin-top:50px;
  position:relative;
  /* margin-left:2vw;
  margin-right:2vw; */
  height:730px;
  position: relative;
  background: white;
  overflow: hidden;
  background-repeat: no-repeat;
  width: 100%;
  background-position: 0 -200px;
  background-size: cover;
  /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url('https://cdn.side.nz/v/612825e5-43bf-4e62-b7ae-c7b11aaba35c.jpg'); */
  background-image: url('https://cdn.side.nz/v/6d822032-4563-49e0-8393-afce5caa2176.jpg');
}

.section-item {
  margin-top:200px;display:flex;
}

.section-text {
  width:30vw;margin-left:10vw;display:flex;flex-direction:column;
}

.section-image {
  width:30vw;margin-left:10vw;display:flex;align-items:center;
}

.section-text-mid {
  width:30vw;margin-left:2vw;display:flex;flex-direction:column;order:2;
}

.section-image-mid {
  width:30vw;margin-left:10vw;display:flex;align-items:center;order:1;
}

.hero-title {
  color:#fff;font-size:65px;
  font-weight:600;
  margin-bottom:30px;
  text-align: center;
}

.hero-subtitle {
  color:#fff;
  font-size:27px;
  font-weight:500;
  text-align:center;
}

@media only screen and (max-width: 768px) {
  .hero-alt {
    padding: 10px;
    margin-top:0;
    height:500px;
    width:unset;
    background-position: unset;
  }

  .section-item {
    flex-direction: column;
    align-items: center;
    margin-top:100px;
    text-align: center;
  }

  .section-text {
    width:100%;
    margin-left:0;
    padding: 15px;
  }

  .section-image {
    width:100%;
    margin-left:0;
    justify-content: center;
  }

  .section-text-mid {
    order:1;
    width:100%;
    margin-left:0;
    padding: 15px;
  }

  .section-image-mid {
    order:2;
    width:100%;
    margin-left:0;
    justify-content: center;
  }

  .points {
    display: flex;
    flex-direction: column;
    margin-left: 13vw;
  }



}

@media only screen and (max-width: 480px) {
  .hero-alt {
    background-image: none;
    background-color: #bbf3fd;
    margin-left:0;
    padding: 10px;
    margin-top:0;
    height:500px;
  }

  .section-item {
    flex-direction: column;
    align-items: center;
    margin-top:100px;
  }

  .section-text {
    width:100%;
    margin-left:0;
    padding: 15px;
  }

  .section-image {
    width:100%;
    margin-left:0;
    justify-content: center;
  }

  .section-text-mid {
    order:1;
    width:100%;
    margin-left:0;
    padding: 15px;
  }

  .section-image-mid {
    order:2;
    width:100%;
    margin-left:0;
    justify-content: center;
  }

  .overlay {
    background-color: unset;
  }

  .hero-title {
    color:#213b55;
  }

  .hero-subtitle {
    color:#213b55;
  }
}
</style>
